import JshModule from '@core/scripts/helper/jsh-module';
import {Slider} from '../../components/slider.js';

do {
  /** @var {{ moduleScope: HTMLElement, config?: { itemsPerSlide: number }}} **/
  const {moduleScope, config} = JshModule('teaser/slider');

  if (moduleScope !== null) {
    /** @var {number} **/ const itemsPerSlide = config?.itemsPerSlide ?? 5;

    const sliderElement = moduleScope.querySelector('.content-platform__slider');

    const prevButton = moduleScope.querySelector('button.-prev');
    const nextButton = moduleScope.querySelector('button.-next');

    const sliderInstance = new Slider(sliderElement);

    prevButton.onclick = () => {
      sliderInstance.slideLeft(itemsPerSlide);
    }

    nextButton.onclick = () => {
      sliderInstance.slideRight(itemsPerSlide);
    }

  }

} while (document.querySelector('[jsh-module="teaser/slider"]') !== null);
